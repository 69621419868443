import React from "react";
import { css } from "@emotion/core";
import { graphql } from "gatsby";
import { Layout } from "../components/Layout";
import { Head } from "../components/Head";
import { NewsCards } from "../components/NewsCards";

const container = css`
  padding: 64px 0 0 0;
`;

export default function News({ data }) {
  const title = "お知らせ";

  return (
    <Layout>
      <Head props={title} />
      <div css={container}>
        <NewsCards props={data} />
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    allMicrocmsNews {
      nodes {
        daytime
        newsImage1 {
          url
        }
        newsImage2 {
          url
        }
        newsImage3 {
          url
        }
        newsImage4 {
          url
        }
        newsText
        newsTitle
        id
      }
    }
  }
`;

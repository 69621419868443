import React from "react";
import { NewsCard } from "./NewsCard";

export const NewsCards = (data) => {
  const newsData = data.props.allMicrocmsNews.nodes;
  // 更新順にするためのコード
  const sortData = newsData.sort(function (a, b) {
    if (a.daytime > b.daytime) {
      return -1;
    } else {
      return 1;
    }
  });
  return sortData?.map((data) => {
    return (
      <NewsCard
        key={data.id}
        props={[
          data.daytime,
          data.newsTitle,
          data.newsText,
          data.newsImage1,
          data.newsImage2,
          data.newsImage3,
          data.newsImage4,
        ]}
      />
    );
  });
};
